<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row gutter-b">
      <div class="col-xxl-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <h6 class="mb-0">Form Role</h6>
          </template>
          <div class="mb-4">
            <h6 class="mb-1">Name</h6>
            <b-form-input v-model="role.name" placeholder="Name"></b-form-input>
          </div>
          <div class="mb-4">
            <h6>Role</h6>
            <b-form-group>
              <b-form-checkbox-group
                id="checkbox-group-1"
                v-model="role.permission_ids"
                :options="listPermission"
                name="flavour-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <template v-slot:footer>
            <b-button
              variant="primary"
              @click="handleRole"
              :disabled="loading"
              v-if="$route.name === 'role-create'"
            >
              {{ loading ? "Loading..." : "Submit" }}
            </b-button>
            <b-button
              variant="warning"
              @click="handleRole"
              :disabled="loading"
              v-else
            >
              {{ loading ? "Loading..." : "Update" }}
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { dateToISOString } from "@/utils/helper";

export default {
  name: "Role",
  data() {
    return {
      role: {
        name: null,
        permission_ids: [],
      },
      photo: null,
      loading: false,
      roles: [],
      listPermission: [],
    };
  },
  components: {},
  mounted() {
    this.getRoles();
    this.getPermission();

    let lastBreadcrumb = "";
    if (this.$route.name === "role-create") {
      lastBreadcrumb = { title: "Create role" };
    } else {
      lastBreadcrumb = { title: "Edit role" };
      this.initData();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Role", route: "/role" },
      lastBreadcrumb,
    ]);
  },
  methods: {
    dateToISOString,
    async handleRole() {
      this.loading = true;
      try {
        let message = "Successfully add role ";
        if (this.$route.name === "role-create") {
          await this.$axios.post(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/role`,
            {
              json: true,
              ...this.role,
            }
          );
        } else {
          const idRole = this.$route.params.id;
          await this.$axios.put(
            `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/role/${idRole}`,
            {
              json: true,
              ...this.role,
            }
          );
          message = "Successfully edit role";
        }
        this.$bvToast.toast(message, {
          title: "Success",
          variant: "success",
          solid: true,
        });
        this.$router.push({ name: "role" });
      } catch ({ response }) {
        this.$bvToast.toast(response.data.message, {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.loading = false;
      }
    },
    handleFile(e) {
      const file = e.target.files[0];
      this.photo = file;
    },
    getRoles() {
      this.$axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/roles`)
        .then(({ data }) => {
          this.roles = [];
          this.roles.push({
            value: "",
            text: "Select Role",
          });
          data.map((item) => {
            this.roles.push({
              value: item.id,
              text: item.name,
            });
          });
        });
    },
    getPermission() {
      this.$axios
        .get(`${process.env.VUE_APP_BASE_API_DADA}/v1/cms/permissions`)
        .then(({ data }) => {
          this.listPermission = [];
          data.map((item) => {
            this.listPermission.push({
              value: item.id,
              text: item.name,
            });
          });
        });
    },
    initData() {
      this.$axios
        .get(
          `${process.env.VUE_APP_BASE_API_DADA}/v1/cms/role/detail/${this.$route.params.id}`
        )
        .then(({ data }) => {
          this.role.name = data.name;

          this.role.permission_ids = data.permissions.map((item) => {
            return item.id;
          });
        });
    },
  },
};
</script>
<style lang="scss"></style>
